import React from 'react';
import cls from 'classnames';

import styles from './style.module.less';

export default ({ title, description, bgColor = 'gray', list, style }) => {
  return (
    <div
      id="card-list"
      className={cls([styles.container, bgColor])}
      style={style}
    >
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.desc}>{description}</p>

        <div className={styles.cards}>
          {(list || []).map(({ src, itemStyle, title, content }, i) => {
            return (
              <div
                key={i}
                className={cls([styles.card, i % 2 === 0 ? 'left' : 'right'])}
              >
                <div className={styles.cardCover}>
                  <img
                    className={styles.cardCoverImg}
                    src={src}
                    style={itemStyle}
                  />
                </div>
                <div className={styles.cardContent}>
                  <h3 className={styles.cardTitle}>{title}</h3>
                  {(content || []).map((c, j) => (
                    <p key={j} className={styles.cardDesc}>
                      {c}
                    </p>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
