import React from 'react';
import cls from 'classnames';

import styles from './style.module.less';

export default ({
  title,
  content,
  itemStyle,
  src,
  bgColor = 'light',
  style,
}) => {
  return (
    <div
      id="student-gallery"
      className={cls([styles.container, bgColor])}
      style={style}
    >
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{title}</h2>

        <div className={styles.gallery}>
          <img className={styles.galleryImg} style={itemStyle} src={src} />
        </div>

        <div className={styles.info}>
          {(content || []).map((c, i) => (
            <p key={i}>{c}</p>
          ))}
        </div>
      </div>
    </div>
  );
};
