import React, { useState, useMemo, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import cls from 'classnames';
import { getWidth } from 'dom-lib';

import Fade from 'components/Fade';
import useLoop from 'utils/useLoop';

import styles from './style.module.less';

const SLIDE_WIDTH = 335;
const SLIDE_MARGIN = 15;

export default ({ title, description, bgColor = 'gray', list, style }) => {
  const len = (list || []).length;
  const [curr, { next, prev, pause }] = useLoop(0, {
    totalSize: len,
    autoplay: false,
    loop: false,
  });
  const [transX, setTransX] = useState(0);
  const handler = useSwipeable({
    onSwipedLeft: next,
    onSwipedRight: prev,
    onSwiping: pause,
  });
  const tags = [
    require('images/icon-taga.png'),
    require('images/icon-tagb.png'),
    require('images/icon-tagc.png'),
    require('images/icon-tagd.png'),
  ];
  const itags = [
    require('images/icon-tag1.png'),
    require('images/icon-tag2.png'),
    require('images/icon-tag3.png'),
    require('images/icon-tag4.png'),
    require('images/icon-tag5.png'),
    require('images/icon-tag6.png'),
  ];

  // 控制器调整偏移
  useEffect(() => {
    let width = getWidth(document.body);

    // NOTICE: 由于mobile设定最大宽度只适配到 375 px
    width = width > 420 ? 420 : width;

    if (curr === 0) {
      // 第一个
      setTransX(0);
    } else if (curr === len - 1) {
      // 最后一个
      setTransX(-(SLIDE_WIDTH * len + SLIDE_MARGIN * (len - 1) - width));
    } else {
      // 中间
      setTransX(
        -((SLIDE_WIDTH + SLIDE_MARGIN) * curr + SLIDE_WIDTH / 2 - width / 2)
      );
    }
  }, [curr, len]);

  return (
    <div
      id="acc-carousel"
      className={cls([styles.container, bgColor])}
      style={style}
    >
      <div className={styles.wrapper}>
        <h2 className={styles.title}>{title}</h2>
        <p className={styles.desc}>{description}</p>

        <div className={styles.slidesWrapper}>
          <div
            {...handler}
            className={styles.slides}
            style={{ transform: `translateX(${transX}px)` }}
          >
            {(list || []).map(({ src, style, title, type, steps }, i) => {
              const sLen = (steps || []).length;

              return (
                <div key={i} className={styles.slide}>
                  <div className={styles.slideCoverWrapper}>
                    <img
                      className={styles.slideCover}
                      style={style}
                      src={src}
                    />
                  </div>
                  <div className={styles.slideMask} />
                  <div className={styles.slideContent}>
                    <h3 className={styles.slideTitle}>
                      <span className={styles.slideTitleA}>{title}</span>
                      <span className={styles.slideTitleB}>({sLen}个阶段)</span>
                    </h3>
                    <ul className={styles.steps}>
                      {(steps || []).map((step, j) => (
                        <li key={j} className={styles.step}>
                          <div className={styles.stepTagWrapper}>
                            <img
                              style={{ width: 22, height: 22 }}
                              src={type === 'index' ? itags[j] : tags[j]}
                            />
                          </div>
                          {step}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Fade in={curr !== i}>
                    <div className={styles.slideNoActiveMask} />
                  </Fade>
                </div>
              );
            })}
          </div>

          {curr > 0 ? (
            <div className={styles.leftBtn} onClick={prev}>
              <img
                style={{ wdith: 5, height: 10 }}
                src={require('images/icon-arrow-r.png')}
              />
            </div>
          ) : null}

          {curr < len - 1 ? (
            <div className={styles.rightBtn} onClick={next}>
              <img
                style={{ wdith: 5, height: 10 }}
                src={require('images/icon-arrow-r.png')}
              />
            </div>
          ) : null}
        </div>

        <div className={styles.dots}>
          {(list || []).map((__, i) => (
            <div
              key={i}
              className={cls([styles.dot, i === curr && 'active'])}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
